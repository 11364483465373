/* Login.css */
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.logo {
  width: 200px; /* change this to your desired logo size */
  margin-bottom: 50px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form input {
  margin: 10px 0;
}
